import React, { useEffect, useState } from 'react';

import ResponsiveImage from './ResponsiveImage';
import ContactSection from './ContactSection';
import PortfolioItemSection from './PortfolioItemSection';

import MacPortrait2w9902xwebp from '../images/MacPortrait2w990@2x.webp';
import MacPortrait2w9901xwebp from '../images/MacPortrait2w990@1x.webp';
import MacPortrait2w9902xjpg from '../images/MacPortrait2w990@2x.jpg';
import MacPortrait2w9901xjpg from '../images/MacPortrait2w990@1x.jpg';
import MacPortrait2w3002xwebp from '../images/MacPortrait2w300@2x.webp';
import MacPortrait2w3001xwebp from '../images/MacPortrait2w300@1x.webp';
import MacPortrait2w3002xjpg from '../images/MacPortrait2w300@2x.jpg';
import MacPortrait2w3001xjpg from '../images/MacPortrait2w300@1x.jpg';
import MacPortraitw3002xwebp from '../images/MacPortraitw300@2x.webp';
import MacPortraitw3001xwebp from '../images/MacPortraitw300@1x.webp';
import MacPortraitw3002xjpg from '../images/MacPortraitw300@2x.jpg';
import MacPortraitw3001xjpg from '../images/MacPortraitw300@1x.jpg';
import ENGProjectPrevieww9351xwebp from '../images/ENGProjectPrevieww935@1x.webp';
import ENGProjectPrevieww9351xjpg from '../images/ENGProjectPrevieww935@1x.jpg';
import ENGProjectPrevieww18702xwebp from '../images/ENGProjectPrevieww1870@2x.webp';
import RKProjectPrevieww9351xjpg from '../images/RKProjectPrevieww935@1x.jpg';
import RKProjectPrevieww18702xwebp from '../images/RKProjectPrevieww1870@2x.webp';
import RKProjectPrevieww9351xwebp from '../images/RKProjectPrevieww935@1x.webp';
import RKProjectPreview18702xjpg from '../images/RKProjectPreview1870@2x.jpg';
import GTBSProjectPrevieww9351xjpg from '../images/GTBSProjectPrevieww935@1x.jpg';
import GTBSProjectPrevieww18702xwebp from '../images/GTBSProjectPrevieww1870@2x.webp';
import GTBSProjectPrevieww9351xwebp from '../images/GTBSProjectPrevieww935@1x.webp';
import GTBSProjectPreview18702xjpg from '../images/GTBSProjectPreview1870@2x.jpg';
import ENGProjectPreview18702xjpg from '../images/ENGProjectPreview1870@2x.jpg';
import STPProjectPrevieww18702xwebp from '../images/STPProjectPrevieww1870@2x.webp';
import STPProjectPrevieww9351xwebp from '../images/STPProjectPrevieww935@1x.webp';
import STPProjectPrevieww9351xjpg from '../images/STPProjectPrevieww935@1x.jpg';
import STPProjectPreview18702xjpg from '../images/STPProjectPreview1870@2x.jpg';
import MBLogo from '../images/MBLogo.svg';

import { breakpointLarge } from './constants';
import {
  StandardParagraph,
  IntroductionHeader,
  IntroductionText,
  SkillsSection,
  Port,
  PortfolioHeader,
  Closer,
  MyStory,
  PolygonBackground,
  PolygonBackground2,
} from './styled';
import { greeting } from './helpers';

const Home = () => {
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;

  return (
    <main>
      <section className="center-with-margin">
        <IntroductionHeader>
          <div className="left">
            <h1>Good {greeting()}, I'm Mac.</h1>
            <h1>Full Stack Developer,</h1>
            <h1>Dad, and volunteer </h1>
            <h1>in Western Washington.</h1>
          </div>
          <div className="image-wrapper right">
            <picture>
              <source
                media={`(max-width: ${breakpointLarge - 1})`}
                srcSet={`${MacPortrait2w9902xwebp} 2x, ${MacPortrait2w9901xwebp} 1x`}
                type="image/webp"
              />
              <source
                media={`(max-width: ${breakpointLarge - 1})`}
                srcSet={`${MacPortrait2w9902xjpg} 2x, ${MacPortrait2w9901xjpg} 1x`}
                type="image/jpg"
              />
              <source
                media={`(min-width: ${breakpointLarge} )`}
                srcSet={`${MacPortrait2w3002xwebp} 2x, ${MacPortrait2w3001xwebp} 1x`}
                type="image/webp"
              />
              <source
                media={`(min-width: ${breakpointLarge} )`}
                srcSet={`${MacPortrait2w3002xjpg} 2x, ${MacPortrait2w3001xjpg} 1x`}
                type="image/jpg"
              />

              <img src={MacPortrait2w9902xjpg} alt="Headshot of Mac Bubb" />
            </picture>
          </div>
        </IntroductionHeader>
        <IntroductionText>
          <div className="left">
            <StandardParagraph>
              I've been solving problems with computers my whole life. Sometimes
              I get to <b>make cool stuff</b>.
            </StandardParagraph>
            <StandardParagraph>
              I used to teach high school math, until I started the most
              important job of my life: stay at home dad
              <span aria-label="img" alt="emoji of a man holding a baby">
                {' '}
                👨‍🍼
              </span>
              . Even so, from websites, to raspberry pis, to digitizing an
              elementary school raffle, I keep finding problems computers can
              solve!
            </StandardParagraph>
          </div>
          <div className="right">
            <StandardParagraph>
              I continue to code and learn new technologies and I have embraced
              web development. I am transitioning away from my role as a stay at
              home dad because I want to learn from more experienced developers
              and make <b>more cool stuff</b> with a team.
            </StandardParagraph>
          </div>
        </IntroductionText>
      </section>
      <PolygonBackground>
        <SkillsSection className="center-with-margin">
          <div className="skills-text-wrapper">
            <h2>My Skills</h2>
            <p>
              I'm always excited to learn new technology for a project. Too many
              times, I've tried to explain to my kids some new framework or
              process I'm pumped about! They are cool about it, but they look
              forward to me finding a team with which to share my excitement.
              These techs are my bread and butter:
            </p>
          </div>
          <div className="skills-list">
            <div className="row">
              <div className="skill">
                <img
                  alt="icon for html5"
                  src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/html5/html5-plain.svg"
                />{' '}
                HTML
              </div>
              <div className="skill">
                <img
                  alt="SASS icon"
                  src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/sass/sass-original.svg"
                />{' '}
                SASS
              </div>
            </div>
            <div className="row">
              <div className="skill">
                <img
                  alt="JS Icon"
                  src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/javascript/javascript-plain.svg"
                />{' '}
                Javascript
              </div>
              <div className="skill">
                <img
                  alt="TS icon"
                  src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/typescript/typescript-plain.svg"
                />{' '}
                TypeScript
              </div>
            </div>
            <div className="row">
              <div className="skill">
                <img
                  alt="React.JS icon"
                  src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original.svg"
                />{' '}
                React
              </div>
              <div className="skill">
                <img
                  alt="Node.js icon"
                  src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/nodejs/nodejs-original.svg"
                />{' '}
                Nodejs
              </div>
            </div>
            <div className="row">
              <div className="skill">
                <img
                  alt="express icon"
                  src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/express/express-original.svg"
                />{' '}
                Express
              </div>
              <div className="skill">
                <img
                  alt="mongodb icon"
                  src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/mongodb/mongodb-plain.svg"
                />{' '}
                MongoDB
              </div>
            </div>
          </div>
        </SkillsSection>

        <Port className="center-with-margin">
          <PortfolioHeader>
            <div className="portfolio-text">
              <h2>My Work</h2>
              <StandardParagraph>
                Some projects meet the needs of clients. Others start from
                personal inspiration. The following is a mixture of each. I love
                to create: seeing an idea transform from a sketch to a
                responsive and graphical site feels nothing short of magical.
              </StandardParagraph>
            </div>
          </PortfolioHeader>
          <div className="portfolio-items">
            <div className="row">
              <PortfolioItemSection
                bigWebp={RKProjectPrevieww18702xwebp}
                smallWebp={RKProjectPrevieww9351xwebp}
                bigJpeg={RKProjectPreview18702xjpg}
                smallJpeg={RKProjectPrevieww9351xjpg}
                altText="runningkindness.com project preview that includes homepage on both a browser and mobile"
                isMobile={isMobile}
                heading="Running Kindness"
                paragraph="A science based fitness blog based on the principal:
              consistency beats intensity."
                link="/RK#top"
              />
              <PortfolioItemSection
                bigWebp={ENGProjectPrevieww18702xwebp}
                smallWebp={ENGProjectPrevieww9351xwebp}
                bigJpeg={ENGProjectPreview18702xjpg}
                smallJpeg={ENGProjectPrevieww9351xjpg}
                altText="Project preview that shows Erin Norman Gardens homepage, about page, and maintenance page."
                isMobile={isMobile}
                heading="Erin Norman Gardens"
                paragraph="A garden practice specializing in sustainable gardening
                    practices."
                link="/ENG#top"
              />
            </div>
            <div className="row">
              <PortfolioItemSection
                bigWebp={STPProjectPrevieww18702xwebp}
                smallWebp={STPProjectPrevieww9351xwebp}
                bigJpeg={STPProjectPrevieww9351xjpg}
                smallJpeg={STPProjectPreview18702xjpg}
                altText="Project preview that shows homepage of Summit Tree Pros on both a regular browser and a phone."
                isMobile={isMobile}
                heading="Summit Tree Pros"
                paragraph="Specializing in tree removal, danger management, and
                    trimming: they climb very tall trees with chainsaws."
                link="/STP#top"
              />
              <PortfolioItemSection
                bigWebp={GTBSProjectPrevieww18702xwebp}
                smallWebp={GTBSProjectPrevieww9351xwebp}
                bigJpeg={GTBSProjectPreview18702xjpg}
                smallJpeg={GTBSProjectPrevieww9351xjpg}
                altText="Two browser screenshots from the Gamertag Brain Tsunami"
                isMobile={isMobile}
                heading="Gamertag Brain Tsunami"
                paragraph="The most epic gamertag generator ever created in the entire
                    history of the internet."
                link="/GTBS#top"
              />
            </div>
          </div>
        </Port>
      </PolygonBackground>
      <ContactSection />
      <PolygonBackground2>
        <Closer className="center-with-margin">
          <MyStory>
            <div className="storyRow">
              <div className="one">
                <h2>My Story</h2>
                <StandardParagraph>
                  If you've made it this far and haven't lost interest, why not
                  hear more of my story?
                </StandardParagraph>
                <h3>Volunteering</h3>
                <StandardParagraph>
                  I believe in equality and justice for all. Everyone benefits
                  from practicing kindness and generosity. This perspective
                  inspires me to volunteer. In college, I volunteered tutoring
                  math with teenaged parents and I participated in service-base
                  spring break trips. After college I{' '}
                  <a
                    href="https://www.mercyvolunteers.org/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    volunteered for a year
                  </a>{' '}
                  as a paralegal at a{' '}
                  <a
                    href="https://phillyvip.org"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    legal aid nonprofit
                  </a>{' '}
                  in Philadelphia.
                </StandardParagraph>
              </div>
              <div className="two">
                <div className="image-wrapper ">
                  <ResponsiveImage
                    bigWebp={MacPortraitw3002xwebp}
                    smallWebp={MacPortraitw3001xwebp}
                    bigJpeg={MacPortraitw3002xjpg}
                    smallJpeg={MacPortraitw3001xjpg}
                    altText="My portrait"
                  />
                </div>
              </div>
            </div>
            <div className="storyRow">
              <div className="three">
                <div className="image-wrapper svg">
                  <img src={MBLogo} alt="Mac Bubb Logo, and M and a B" />
                </div>
              </div>
              <div className="four">
                <h3>Teaching</h3>
                <StandardParagraph>
                  I became a teacher to help those who don't share my
                  privileges. I taught fulltime in the School District of
                  Philadelphia while earning my Masters in Education from the
                  University of Pennsylvania. I also taught in Title I schools
                  in Charlottesville and San Francisco. My students and I shared
                  the joys of success, and the challenges of learning in a
                  difficult system. I've never had a job where time went by so
                  quickly. The work of a teacher is demanding and I honed my
                  skills in communication, motivation, and empathy. The
                  experience left me truly appreciative of the talent,
                  brilliance, and service of our teachers.
                </StandardParagraph>
                <h3>Fatherhood</h3>
                <StandardParagraph>
                  I moved from teaching to the most important job of my career:
                  stay at home dad. Our family is lucky to have a parent at home
                  full time, so I have used my availability and talents to
                  volunteer. I co-chaired our school PTA's primary fundraiser,
                  I've tutored math, and I've coached soccer, baseball, and
                  chess. Coaching has taken all of the fun of teaching and just
                  condensed it. My goal is to unconditionally support my players
                  and use the power of good practice and communication. I also
                  teach them how to handle the frustration of not being perfect
                  as they develop new skills. I'm not really leaving my job as
                  dad, that's not biologically possible. I am simply
                  transitioning to the role of working dad.
                </StandardParagraph>
              </div>
            </div>
          </MyStory>
        </Closer>
      </PolygonBackground2>
    </main>
  );
};

export default Home;
