import React from 'react';
import ResponsiveImage from './ResponsiveImage';
import { StandardParagraph, PortfolioItem } from './styled';
import { HashLink as Link } from 'react-router-hash-link';
import Tilt from 'react-parallax-tilt';

function PortfolioItemSection(props) {
  const {
    bigWebp,
    smallWebp,
    bigJpeg,
    smallJpeg,
    altText,
    isMobile,
    heading,
    paragraph,
    link,
  } = props;

  return !isMobile ? (
    <PortfolioItem>
      <Link to={link}>
        <Tilt
          className="tilt"
          tiltMaxAngleX={5}
          tiltMaxAngleY={5}
          transitionSpeed={1000}
          scale={1}
          gyroscope={true}
          glare={true}
        >
          <ResponsiveImage
            bigWebp={bigWebp}
            smallWebp={smallWebp}
            bigJpeg={bigJpeg}
            smallJpeg={smallJpeg}
            altText={altText}
          />
        </Tilt>
        <h3>{heading}</h3>
        <StandardParagraph>{paragraph}</StandardParagraph>
      </Link>
    </PortfolioItem>
  ) : (
    <PortfolioItem>
      <Link to={link}>
        <ResponsiveImage
          bigWebp={bigWebp}
          smallWebp={smallWebp}
          bigJpeg={bigJpeg}
          smallJpeg={smallJpeg}
          altText={altText}
        />
        <h3>{heading}</h3>
        <StandardParagraph>{paragraph}</StandardParagraph>
      </Link>
    </PortfolioItem>
  );
}

export default PortfolioItemSection;
