import React from 'react';
import ResponsiveImage from './ResponsiveImage';
import ContactSection from './ContactSection';

import STPProjectPreview2w11002xjpg from '../images/STP/STPProjectPreview2w1100@2x.jpg';
import STPProjectPreview2w11002xwebp from '../images/STP/STPProjectPreview2w1100@2x.webp';
import STPProjectPreview2w11001xjpg from '../images/STP/STPProjectPreview2w1100@1x.jpg';
import STPProjectPreview2w11001xwebp from '../images/STP/STPProjectPreview2w1100@1x.webp';
import STPLong1w5222xwebp from '../images/STP/STPLong1w522@2x.webp';
import STPLong1w5222xjpg from '../images/STP/STPLong1w522@2x.jpg';
import STPLong1w5221xwebp from '../images/STP/STPLong1w522@1x.webp';
import STPLong1w5221xjpg from '../images/STP/STPLong1w522@1x.jpg';
import STPLong2w5222xwebp from '../images/STP/STPLong2w522@2x.webp';
import STPLong2w5222xjpg from '../images/STP/STPLong2w522@2x.jpg';
import STPLong2w5221xwebp from '../images/STP/STPLong2w522@1x.webp';
import STPLong2w5221xjpg from '../images/STP/STPLong2w522@1x.jpg';

import {
  StandardParagraph,
  PolygonBackground3,
  PortfolioPage,
  PortfolioSection,
  PortfolioLink,
  BrowserImage,
  LongImage,
} from './styled';

const Home = () => {
  return (
    <main>
      <PortfolioPage className="center-with-margin portfolio-animation">
        <h1>Summit Tree Pros</h1>
        <h2>Overview</h2>
        <StandardParagraph>
          Owners JJ and Stephanie had one major requirement: they wanted the
          website to look like a business card on mobile devices. It was
          essential for visitors to find his phone number immediately. They
          provided a logo and copy and asked me to make something that would
          cooperate with that aesthetic. They also wanted to have a sign up list
          for people to receive free firewood or wood chips. It turns out tree
          services have extra wood on their hands, all the dang time!
        </StandardParagraph>

        <PortfolioLink>
          <a
            href="https://summittreepros.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Visit Site
          </a>
          <a
            href="https://github.com/macbubb/foundation-summit"
            target="_blank"
            rel="noopener noreferrer"
          >
            See Code
          </a>
        </PortfolioLink>
        <BrowserImage className="portfolio-animation">
          <ResponsiveImage
            bigWebp={STPProjectPreview2w11002xwebp}
            smallWebp={STPProjectPreview2w11001xwebp}
            bigJpeg={STPProjectPreview2w11002xjpg}
            smallJpeg={STPProjectPreview2w11001xjpg}
            altText="Project preview that shows homepage of Summit Tree Pros on both a regular browser and a phone."
          />
        </BrowserImage>
      </PortfolioPage>
      <PolygonBackground3>
        <PortfolioPage className="center-with-margin grab-padding-for-polygon portfolio-animation">
          <PortfolioSection className="steal-margin-for-polygon">
            <h2>Design</h2>
            <StandardParagraph>
              The big feature of this design is the business card hero. On
              mobile devices it fills the screen, and a user can easily click on
              JJ's number to place a call. It's simple and clean. It also has a
              small clickable arrow pointing down, signaling there is more site.
              You can scroll, or click and be scrolled, to learn more about
              Summit and their services. The owners also love the outdoors so I
              included background images of the local flora behind many
              elements. Summit's work is impressive. When needed, they can take
              down trees from the top, section by section, using ropes to safely
              lower each piece. I included a series of photos showing a tree
              coming down in this manner.
            </StandardParagraph>
          </PortfolioSection>
          <PortfolioSection>
            <LongImage>
              <ResponsiveImage
                bigWebp={STPLong1w5222xwebp}
                smallWebp={STPLong1w5221xwebp}
                bigJpeg={STPLong1w5222xjpg}
                smallJpeg={STPLong1w5221xjpg}
                altText="Summit Tree Service's main page, https://summittreepros.com"
              />
            </LongImage>
          </PortfolioSection>
          <PortfolioSection>
            <h2>Web Stack and Explanation</h2>
            <StandardParagraph>
              HTML and the Foundation CSS framework. The site relies heavily on
              media queries to create the mobile full screen business card. On
              larger devices, the same content is restyled by the queries into a
              large navigation bar. A contact form is implemented with a custom
              Mailchimp overlay popup. JS is used for the small scroll button.
            </StandardParagraph>
          </PortfolioSection>
        </PortfolioPage>
      </PolygonBackground3>

      <PortfolioPage className="center-with-margin steal-margin-for-polygon">
        <PortfolioSection className="steal-margin-for-polygon">
          <h2>Lessons Learned</h2>
          <StandardParagraph>
            The big takeaway was the importance of asking for specific details
            working with a client. After the initial design, we discussed their
            feedback and I asked follow up questions to create a checklist of
            modifications. The adjustments were made that day, and the owners
            loved seeing the site evolve. They didn't expect it to be so
            adaptable and were very happy with the process. Owner Stephanie kept
            telling me, "You should have told me this was going to be so much
            fun!"
          </StandardParagraph>
        </PortfolioSection>

        <LongImage>
          <ResponsiveImage
            bigWebp={STPLong2w5222xwebp}
            smallWebp={STPLong2w5221xwebp}
            bigJpeg={STPLong2w5222xjpg}
            smallJpeg={STPLong2w5221xjpg}
            altText="Summit Tree Service's customer testimonial page."
          />
        </LongImage>
        <PortfolioSection>
          <ContactSection />
        </PortfolioSection>
      </PortfolioPage>
    </main>
  );
};

export default Home;
