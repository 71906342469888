import React from 'react';
import styled from 'styled-components';
import MBLogo from '../images/MBLogo.svg';
import LILogo from '../images/LILogo.svg';
import GHLogo from '../images/GHLogo.svg';
import { maxWidth, breakpointSmall, mSections } from './constants';

const NavBar = styled.div`
  max-width: ${maxWidth};
  margin-top: ${mSections};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-right: 2rem;
  .right img,
  .left img {
    height: 50px;
    transition: all 0.2s linear;
    &:hover {
      opacity: 0.7;
    }
  }

  @media screen and (min-width: ${breakpointSmall}) {
    justify-content: space-between;
  }
  .right {
    a {
      margin-left: 2rem;
    }
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    img {
      opacity: 0;
      transform: translateY(-30px);
    }
  }
  .left {
    opacity: 0;
  }
`;

function Navigation() {
  window.addEventListener('load', () => {
    document.querySelector('body').classList.add('loaded');
  });
  const inViewport = (entries, observer) => {
    entries.forEach((entry) => {
      entry.target.classList.toggle('is-inViewport', entry.isIntersecting);
    });
  };

  const Obs = new IntersectionObserver(inViewport);
  const obsOptions = {}; //See: https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API#Intersection_observer_options

  // Attach observer to every [data-inviewport] element:
  const ELs_inViewport = document.querySelectorAll('[data-inviewport]');
  ELs_inViewport.forEach((EL) => {
    Obs.observe(EL, obsOptions);
  });

  return (
    <NavBar className="center-with-margin">
      <section className="left">
        <a href="/">
          <img src={MBLogo} alt="Mac Bubb logo, an M and B" />
        </a>
      </section>
      <section className="right">
        <a
          href="https://github.com/macbubb"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={GHLogo} alt="github logo, octocat!" />
        </a>
        <a
          href="https://www.linkedin.com/in/mac-bubb/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={LILogo} alt="linkedin logo" />
        </a>
      </section>
    </NavBar>
  );
}

export default Navigation;
