import React from 'react';
import { Contact, StandardParagraph } from './styled';
import Envelope from '../images/envelope.svg';
import LILogo from '../images/LILogo.svg';
import Obfuscate from 'react-obfuscate';

function ContactSection() {
  return (
    <Contact className="center-with-margin">
      <h2>Looking for a Developer?</h2>
      <StandardParagraph>
        I'm available. I'm looking for a project and team that can help me
        improve as a developer and learn as I create. I'd love to hear about
        your team's project and share more of my background. Send me an e-mail
        or reach me on LinkedIn.
      </StandardParagraph>
      <div className="contact-wrapper">
        <Obfuscate email="bubbem@gmail.com">
          <img src={Envelope} alt="An envelope icon" />
        </Obfuscate>
        <a
          href="https://www.linkedin.com/in/mac-bubb/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={LILogo} alt="linkedin icon" />
        </a>
      </div>
    </Contact>
  );
}

export default ContactSection;
