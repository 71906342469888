import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Navigation, Footer, Home, ENG, RK, GTBS, STP } from './components';
ReactDOM.render(
  <Router>
    <Navigation className="navigation" />
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/STP" element={<STP />} />
      <Route path="/ENG" element={<ENG />} />
      <Route path="/RK" element={<RK />} />
      <Route path="/GTBS" element={<GTBS />} />
    </Routes>
    <Footer />
  </Router>,

  document.getElementById('root')
);

serviceWorker.unregister();
