import React from 'react';
import ResponsiveImage from './ResponsiveImage';
import ResponsiveAnimatedImage from './ResponsiveAnimatedImage';
import ContactSection from './ContactSection';

// import GTBSProjectPreview from '../images/GTBS/GTBSProjectPreview3.jpg';
import GTBSLong1w5222xwebp from '../images/GTBS/GTBSLong1w522@2x.webp';
import GTBSLong1w5222xjpg from '../images/GTBS/GTBSLong1w522@2x.jpg';
import GTBSLong1w5221xwebp from '../images/GTBS/GTBSLong1w522@1x.webp';
import GTBSLong1w5221xjpg from '../images/GTBS/GTBSLong1w522@1x.jpg';
import GTBSLong2w5222xwebp from '../images/GTBS/GTBSLong2w522@2x.webp';
import GTBSLong2w5222xjpg from '../images/GTBS/GTBSLong2w522@2x.jpg';
import GTBSLong2w5221xwebp from '../images/GTBS/GTBSLong2w522@1x.webp';
import GTBSLong2w5221xjpg from '../images/GTBS/GTBSLong2w522@1x.jpg';
import GTBSProjectPreview2w11002xjpeg from '../images/GTBS/GTBSProjectPreview2w1100@2x.jpeg';
import GTBSProjectPreview2w11002xwebp from '../images/GTBS/GTBSProjectPreview2w1100@2x.webp';
import GTBSProjectPreview2w11001xjpeg from '../images/GTBS/GTBSProjectPreview2w1100@1x.jpeg';
import GTBSProjectPreview2w11001xwebp from '../images/GTBS/GTBSProjectPreview2w1100@1x.webp';
import GTBSAnimated from '../images/GTBS/GTBSAnimated.gif';
import GTBSAnimatedBigWebp from '../images/GTBS/GTBSAnimatedBig.webp';
import GTBSAnimatedSmallWebp from '../images/GTBS/GTBSAnimatedSmall.webp';

import {
  StandardParagraph,
  PolygonBackground3,
  PortfolioPage,
  PortfolioSection,
  PortfolioLink,
  BrowserImage,
  BrowserImageSmall,
  LongImage,
} from './styled';

const Home = () => {
  return (
    <main>
      <PortfolioPage className="center-with-margin portfolio-animation">
        <h1>Gamertag Brain Tsunami</h1>
        <h2>Overview</h2>
        <StandardParagraph>
          This project came about as I was learning react and my kids were
          experimenting with gamertags and online gamer identities. We checked
          out a couple gamertag generator sites and the tags were fine, but the
          interfaces were generic and forgettable. I was inspired to code a
          generator that shares the aesthetics of a video game and creates more
          of an experience. Even the dialogue boxes in games these days look
          cool! I decided to make each gamertag come with a unique "card".
        </StandardParagraph>

        <PortfolioLink>
          <a
            href="https://gamertagbraintsunami.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Visit Site
          </a>
          <a
            href="https://github.com/macbubb/gamer-tag-gen"
            target="_blank"
            rel="noopener noreferrer"
          >
            See Code
          </a>
        </PortfolioLink>
        <BrowserImage className="portfolio-animation">
          <ResponsiveImage
            bigWebp={GTBSProjectPreview2w11002xwebp}
            smallWebp={GTBSProjectPreview2w11001xwebp}
            bigJpeg={GTBSProjectPreview2w11002xjpeg}
            smallJpeg={GTBSProjectPreview2w11001xjpeg}
            altText="3 mobile browsers that have created the gamertags HypoxicStinker, MegaStack, and LoyalBug"
          />
        </BrowserImage>
      </PortfolioPage>
      <PolygonBackground3>
        <PortfolioPage className="center-with-margin grab-padding-for-polygon portfolio-animation">
          <PortfolioSection className="steal-margin-for-polygon">
            <h2>Design</h2>
            <StandardParagraph>
              Each gamertag is displayed in a unique card that stays with the
              tag. To achieve this, I wrote a function that generates random
              splatters of paint behind the card and behind each gamertag is a
              somewhat jagged randomized quadrilateral. Originally the splatters
              were red, like blood, using the hue from the Akira poster, because
              <span role="img" aria-label="3 rock on symbols">
                🤘🤘🤘
              </span>
              , but later I went for a more G-rated look with color options:
              black ink, rainbow paint, or red (blood). The other feature of
              this was the button to generate a tag. I wanted each press to be
              an event, an unveiling of one more unique card, so I searched
              codepen and found a clever 3d-button idea that I adapted. I
              incorporated the opposite corner edge radius design into other
              components to add some unity to the design.
            </StandardParagraph>
          </PortfolioSection>
          <PortfolioSection>
            <LongImage>
              <ResponsiveImage
                bigWebp={GTBSLong1w5222xwebp}
                smallWebp={GTBSLong1w5221xwebp}
                bigJpeg={GTBSLong1w5222xjpg}
                smallJpeg={GTBSLong1w5221xjpg}
                altText="Gamertag site showing 7 different gamertags including RuggedMamba"
              />
            </LongImage>
          </PortfolioSection>
          <PortfolioSection>
            <h2>Web Stack and Explanation</h2>
            <StandardParagraph>
              React for the win. This was my first react project and I've been
              hooked ever since! I did utilize a keyboard shortcut library, but
              couldn't find any other libraries or components to make the cards
              in my vision. CSS transformations are the heart of the tech,
              leveraged with react for user interaction. To create a custom
              paint color toggle I drew my own interface with SVGs and added
              interactability with react.
            </StandardParagraph>
          </PortfolioSection>
          <PortfolioSection>
            <BrowserImageSmall>
              <ResponsiveAnimatedImage
                bigWebp={GTBSAnimatedBigWebp}
                smallWebp={GTBSAnimatedSmallWebp}
                smallGif={GTBSAnimated}
                altText="Animated GIF showing the gamertag site run"
              />
            </BrowserImageSmall>
          </PortfolioSection>
        </PortfolioPage>
      </PolygonBackground3>

      <PortfolioPage className="center-with-margin steal-margin-for-polygon">
        <PortfolioSection className="steal-margin-for-polygon">
          <h2>Lessons Learned</h2>
          <StandardParagraph>
            React rules. Over and over again in this project I had giddy moments
            as I discovered ways to bring my ideas to life with react. The
            biggest challenge was making the card backgrounds. They were
            generated from 8 splatters, randomly selected from open source SVG
            splatter images. Each splatter was then transformed randomly 7
            different ways with CSS, those values were saved in state and
            applied as needed as inline CSS. This gave me the flexibility to
            keep the splatters completely random, and transferable to the side
            list so each gamertag is a unique card that can be collected.
            Writing the welcome menu and including pagination was a breeze with
            react. Coding the rating component, and seeing the SVG stars fill up
            when clicked was delightful.{' '}
          </StandardParagraph>
          <StandardParagraph>
            I do have a sneaking suspicion that the many features and my
            autodidactism lead to spaghetti code.{' '}
            <b>Learning how to tighten it up following best practices</b> is a
            big reason why I want to work on a team. I am still very pleased and
            proud of this project. If I ever feel a little unsure of my coding,
            I hit up the site, find a cool gamertag (eg ShiftyFlex) and remind
            myself: ok, <b>I'm pretty nice with it</b>.
          </StandardParagraph>
        </PortfolioSection>

        <LongImage>
          <ResponsiveImage
            bigWebp={GTBSLong2w5222xwebp}
            smallWebp={GTBSLong2w5221xwebp}
            bigJpeg={GTBSLong2w5222xjpg}
            smallJpeg={GTBSLong2w5221xjpg}
            altText="Gamertag website showing an assortment of gamertags including HiddenBullet and SwiftMage"
          />
        </LongImage>
        <PortfolioSection>
          <ContactSection />
        </PortfolioSection>
      </PortfolioPage>
    </main>
  );
};

export default Home;
