export const maxWidth = '1150px';

export const breakpointLarge = '1000px';
export const breakpointMedium = '800px';
export const breakpointSmall = '500px';

export const mSections = '5rem';
export const mHtP = '3rem';
export const mEls = '2rem';
export const mBetweenParallelPs = '2rem';
export const polygonColor = '#fff7f3';

export const skills = [
  {
    alt: 'icon for html5',
    src: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/html5/html5-plain.svg',
    text: 'HTML',
  },
  {
    alt: 'SASS icon',
    src: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/sass/sass-original.svg',
    text: 'SASS',
  },
  {
    alt: 'JS Icon',
    src: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/javascript/javascript-plain.svg',
    text: 'Javascript',
  },
  {
    alt: 'TS icon',
    src: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/typescript/typescript-plain.svg',
    text: 'TypeScript',
  },
  {
    alt: 'React.JS icon',
    src: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original.svg',
    text: 'React',
  },
  {
    alt: 'Node.js icon',
    src: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/nodejs/nodejs-original.svg',
    text: 'Nodejs',
  },
  {
    alt: 'express icon',
    src: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/express/express-original.svg',
    text: 'Express',
  },
  {
    alt: 'mongodb icon',
    src: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/mongodb/mongodb-plain.svg',
    text: 'MongoDB',
  },
];
