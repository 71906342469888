import React from 'react';

function ResponsiveImage(props) {
  const { bigWebp, smallWebp, bigJpeg, smallJpeg, altText } = props;
  return (
    <picture>
      <source srcSet={`${bigWebp} 2x, ${smallWebp} 1x`} type="image/webp" />
      <source srcSet={`${bigJpeg} 2x, ${smallJpeg} 1x`} type="image/jpg" />
      <img src={smallJpeg} alt={altText} />
    </picture>
  );
}

export default ResponsiveImage;
