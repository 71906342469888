import React from 'react';

import ResponsiveImage from './ResponsiveImage';
import ContactSection from './ContactSection';

import RKBrowser1w11002xwebp from '../images/RK/RKBrowser1w1100@2x.webp';
import RKBrowser1w11002xjpg from '../images/RK/RKBrowser1w1100@2x.jpg';
import RKBrowser1w11001xwebp from '../images/RK/RKBrowser1w1100@1x.webp';
import RKBrowser1w11001xjpg from '../images/RK/RKBrowser1w1100@1x.jpg';
import RKBrowserw10442xwebp from '../images/RK/RKBrowserw1044@2x.webp';
import RKBrowserw10442xjpg from '../images/RK/RKBrowserw1044@2x.jpg';
import RKBrowserw5221xjpg from '../images/RK/RKBrowserw522@1x.jpg';
import RKBrowserw5221xwebp from '../images/RK/RKBrowserw522@1x.webp';
import RKBrowser5w14422xwebp from '../images/RK/RKBrowser5w1442@2x.webp';
import RKBrowser5w14422xjpg from '../images/RK/RKBrowser5w1442@2x.jpg';
import RKBrowser5w7211xjpg from '../images/RK/RKBrowser5w721@1x.jpg';
import RKBrowser5w7211xwebp from '../images/RK/RKBrowser5w721@1x.webp';
import RKBrowser6w14422xwebp from '../images/RK/RKBrowser6w1442@2x.webp';
import RKBrowser6w14422xjpg from '../images/RK/RKBrowser6w1442@2x.jpg';
import RKBrowser6w7211xjpg from '../images/RK/RKBrowser6w721@1x.jpg';
import RKBrowser6w7211xwebp from '../images/RK/RKBrowser6w721@1x.webp';

import {
  StandardParagraph,
  PolygonBackground3,
  PortfolioPage,
  PortfolioSection,
  PortfolioLink,
  BrowserImage,
  LongImage,
  ParallelImages,
} from './styled';

const Home = () => {
  return (
    <main>
      <PortfolioPage className="center-with-margin portfolio-animation">
        <h1>Running Kindness</h1>
        <h2>Overview</h2>
        <StandardParagraph>
          I am an avid runner and fitness transformed my health. I've embraced a
          philosophy of self kindness and regular exercise that I wanted to
          share with others. This site teaches and encourages visitors to become
          their own coach.
        </StandardParagraph>

        <PortfolioLink>
          <a
            href="https://runningkindness.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Visit Site
          </a>
          <a
            href="https://bitbucket.org/madronaweb/running-kindness/src/master/"
            target="_blank"
            rel="noopener noreferrer"
          >
            See Code
          </a>
        </PortfolioLink>
        <BrowserImage className="portfolio-animation">
          <ResponsiveImage
            bigWebp={RKBrowser1w11002xwebp}
            smallWebp={RKBrowser1w11001xwebp}
            bigJpeg={RKBrowser1w11002xjpg}
            smallJpeg={RKBrowser1w11001xjpg}
            altText="Screenshot that reads Love yourself by learning to run. Learn to run by loving yourself"
          />
        </BrowserImage>
      </PortfolioPage>
      <PolygonBackground3>
        <PortfolioPage className="center-with-margin grab-padding-for-polygon portfolio-animation">
          <PortfolioSection className="steal-margin-for-polygon">
            <h2>Design</h2>
            <StandardParagraph>
              Design is based on accessibility and minimalism with moments to
              emphasize key content. For example, the opening page full screen
              hero, smaller heros, quotes, and wide aspect ratio images. Each
              blog post begins with a short list hero summarizing the post's
              content.
            </StandardParagraph>
          </PortfolioSection>
          <PortfolioSection>
            <LongImage>
              <ResponsiveImage
                bigWebp={RKBrowserw10442xwebp}
                smallWebp={RKBrowserw5221xwebp}
                bigJpeg={RKBrowserw10442xjpg}
                smallJpeg={RKBrowserw5221xjpg}
                altText="Long screenshot of Running Kindness Blog"
              />
            </LongImage>
          </PortfolioSection>
          <PortfolioSection>
            <h2>Web Stack and Explanation</h2>
            <StandardParagraph>
              This site was predominantly created with pure HTML and SCSS. I
              implemented a third-party independent contact form with AJAX and
              PHP script. Only a scattering of JS was used for downsizing the
              menu on scroll and handling cookies and the EU agreement footer.
            </StandardParagraph>
          </PortfolioSection>
        </PortfolioPage>
      </PolygonBackground3>

      <PortfolioPage className="center-with-margin steal-margin-for-polygon">
        <PortfolioSection className="steal-margin-for-polygon">
          <h2>Lessons Learned</h2>
          <StandardParagraph>
            I was very particular about writing reusable CSS classes in this
            project. This helped create a unified design and make generating
            future blog posts much faster. If I were to spend the time, I could
            use the classes to implement a CMS. This project leveraged the power
            of CSS media queries. The main page hero was harder than I
            anticipated to get just right. After testing countless browser
            orientations and sizes, I wrote four media queries to achieve the
            design goal in both landscape and portrait. In the past I've used
            the Foundation framework, but in this project Foundation was only
            helpful for the development environment. I've since dabbled with
            Grunt for the same tools. This is the first project I began to use
            the imagemagick command line tool to automate some of the image
            processing.
          </StandardParagraph>
        </PortfolioSection>
        <ParallelImages>
          <ResponsiveImage
            bigWebp={RKBrowser5w14422xwebp}
            smallWebp={RKBrowser5w7211xwebp}
            bigJpeg={RKBrowser5w14422xjpg}
            smallJpeg={RKBrowser5w7211xjpg}
            altText="Screenshot of Running Kindness Blog article on hydration."
          />
          <ResponsiveImage
            bigWebp={RKBrowser6w14422xwebp}
            smallWebp={RKBrowser6w7211xwebp}
            bigJpeg={RKBrowser6w14422xjpg}
            smallJpeg={RKBrowser6w7211xjpg}
            altText="Screenshot of Running Kindess Blog article on the effects of vigorous exercise."
          />
        </ParallelImages>
        <PortfolioSection>
          <ContactSection />
        </PortfolioSection>
      </PortfolioPage>
    </main>
  );
};

export default Home;
