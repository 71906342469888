import styled from 'styled-components';
import {
  maxWidth,
  breakpointMedium,
  breakpointSmall,
  breakpointLarge,
  mSections,
  mHtP,
  mEls,
  mBetweenParallelPs,
  polygonColor,
} from './constants';

export const StandardParagraph = styled.p`
  /* opacity: 0; */
  margin-bottom: 2rem;
  text-align: left;
  font-size: min(6vw, 1.2rem);
  a {
    font-size: inherit;
    font-weight: 600;
    color: #0073ac;
  }
`;

export const PolygonBackground = styled.div`
  /* background-color: #e7f7ff;
   */
  background-color: ${polygonColor};

  clip-path: polygon(0 3%, 37% 0, 100% 5%, 100% 98%, 55% 100%, 0 96%);
`;

export const PolygonBackground2 = styled.div`
  background-color: ${polygonColor};
  /* background-color: #181919; */
  /* color: #f3fbff; */
  clip-path: polygon(0 5%, 25% 0, 100% 5%, 100% 95%, 55% 100%, 0 95%);
`;

export const PolygonBackground3 = styled.div`
  background-color: ${polygonColor};
  /* background-color: #181919; */
  /* color: #f3fbff; */
  clip-path: polygon(0 2%, 15% 1%, 100% 4%, 100% 95%, 55% 100%, 0 95%);
`;

export const PolygonBackgroundWhite = styled.div`
  min-height: 1000px;
  width: 100vw;
  clip-path: polygon(0 100%, 100% 100%, 100% 0, 0 0);
`;

export const IntroductionHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: ${mSections};
  max-width: ${maxWidth};

  h1 {
    font-size: min(6vw, 3rem);
    opacity: 0;
    font-weight: 900;
    transform: translateY(30px);
  }
  img {
    align-items: center;
    opacity: 0;
    transform: translateY(30px);
    padding-top: ${mSections};
    width: 95%;
    max-width: 600px;
    filter: drop-shadow(0 0 0.75rem rgba(87, 87, 87, 0.5));
  }
  @media screen and (min-width: ${breakpointLarge}) {
    margin-top: 9rem;
    flex-direction: row;
    img {
      padding: 0 0rem 0 0;
    }
    .right {
      display: flex;
      align-items: center;
      width: 40%;
    }
    .left {
      width: 60%;
    }
  }
  @media screen and (min-width: ${breakpointSmall}) {
    margin-top: ${mSections};
  }
`;

export const IntroductionText = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin-top: ${mSections};
  font-size: 1.2rem;
  max-width: ${maxWidth};

  @media screen and (min-width: ${breakpointMedium}) {
    flex-direction: row;
    .right p {
      margin-left: ${mBetweenParallelPs};
    }
  }
`;

export const SkillsSection = styled.div`
  padding-top: ${mSections};

  display: flex;
  flex-direction: column;
  .skills-list {
    display: flex;
    flex-direction: column;
    list-style: none;
    line-height: 1.5;
    justify-content: flex-start;
    .row {
      flex-direction: column;
    }
    .skill {
      opacity: 0;
      transform: translateY(30px);
      font-weight: 900;
      font-size: 1.2rem;
      margin-left: ${mEls};
    }
  }
  h2 {
    opacity: 0;
    transform: translateY(30px);
    margin-bottom: ${mHtP};
  }
  img {
    height: 20px;
    transform: translateY(-2px);
  }
  .skills-text-wrapper {
    p {
      opacity: 0;
      margin-bottom: 2rem;
      text-align: left;
      font-size: min(6vw, 1.2rem);
    }
  }
  @media screen and (min-width: ${breakpointMedium}) {
    .skill {
      &:nth-child(2) {
        margin-top: 0.5rem;
      }
    }
    .skills-list {
      padding: 0;
      flex-direction: row;
      justify-content: space-around;
    }
  }
`;
export const Port = styled.div`
  margin-top: ${mSections};
  padding-bottom: ${mSections};
  .port-items {
    display: flex;
    flex-direction: column;
    width: 90%;
  }
  .row {
    justify-content: space-around;
    flex-direction: column;
    @media screen and (min-width: ${breakpointLarge}) {
      flex-direction: row;
    }
  }
`;
export const PortfolioHeader = styled.div`
  h2,
  p {
    padding-bottom: ${mHtP};
  }
`;
export const PortfolioItem = styled.div`
  width: 100%;
  justify-content: flex-start;
  transition: all 0.4s;
  padding: 0px 20px;
  &:hover {
    /* transform: scale(102%); */
  }

  img {
    width: 100%;
    height: 300px;
    object-fit: contain;
    margin-bottom: ${mEls};
    filter: drop-shadow(0 0 0.75rem rgba(87, 87, 87, 0.5));
  }
  @media screen and (min-width: ${breakpointMedium}) {
    object-fit: cover;
  }
  @media screen and (min-width: ${breakpointLarge}) {
    width: 45%;
  }
`;
export const Closer = styled.section`
  padding-bottom: ${mSections};
  .logo-wrapper {
    margin: auto;
    text-align: center;
    margin-bottom: ${mSections};
    img {
      width: 30%;
      fill: green;
    }
  }
`;

export const Contact = styled.div`
  h2 {
    margin-bottom: ${mHtP};
  }
  .contact-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding-top: 2rem;

    img {
      height: 80px;
    }
    a:first-child {
      img {
        margin-top: -12px;
        height: 105px;
      }
    }
  }
`;

export const MyStory = styled.div`
  padding-top: ${mSections};
  display: flex;
  flex-direction: column;
  display: flex;
  h2 {
    margin-bottom: ${mHtP};
  }
  img {
    margin: 2rem 0;
    /* width: 65%; */
    max-width: 300px;
  }
  .svg {
    img {
      fill: #fff;
      //https://codepen.io/sosuke/pen/Pjoqqp convert hex color to filter
      filter: invert(41%) sepia(26%) saturate(351%) hue-rotate(166deg)
        brightness(94%) contrast(89%);
      width: 250px;
    }
  }
  .image-wrapper {
    text-align: center;
    margin: auto;
  }
  .storyRow {
    display: flex;
    flex-direction: column;
    &:last-child {
      .three {
        margin-left: 0;
        order: 1;
      }
      .four {
        order: 2;
      }
    }
  }

  @media screen and (min-width: ${breakpointMedium}) {
    .storyRow {
      flex-direction: row;
      &:last-child {
        .three {
          order: 1;
        }
      }
    }

    flex-direction: column;
    img {
      width: 100%;
      margin: 0;
      max-width: 300px;
    }
    .one,
    .four {
      flex-direction: column;
      width: 70%;
    }
    .two,
    .three {
      display: flex;
      align-content: center;
      margin-left: 2rem;
      width: 30%;
    }
  }
`;
export const PortfolioPage = styled.section`
  margin-top: ${mSections};
  h1 {
    font-size: 4rem;
    margin-bottom: ${mSections};
  }
`;

export const PortfolioSection = styled.section`
  margin-top: ${mSections};
`;

export const PortfolioLink = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: ${mSections};
  margin-bottom: ${mSections};
`;

export const BrowserImage = styled.div`
  text-align: center;
  margin: auto;
  filter: drop-shadow(0 0 0.75rem rgba(87, 87, 87, 0.5));
  img {
    width: 95%;
  }
`;
export const BrowserImageSmall = styled.div`
  text-align: center;
  margin: auto;
  filter: drop-shadow(0 0 0.75rem rgba(87, 87, 87, 0.5));
  img {
    border-radius: 1%;
  }
`;

export const LongImage = styled.div`
  margin: auto;
  text-align: center;
  filter: drop-shadow(0 0 0.75rem rgba(87, 87, 87, 0.5));
  picture,
  img {
    border-radius: 1%;
    width: 95%;
  }
  @media screen and (min-width: ${breakpointMedium}) {
    picture,
    img {
      border-radius: 1%;
      width: 45%;
    }
  }
`;

export const ParallelImages = styled.div`
  display: flex;
  filter: drop-shadow(0 0 0.75rem rgba(87, 87, 87, 0.5));
  flex-direction: column;
  text-align: center;
  margin: auto;
  picture,
  img {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: auto;
    border-radius: 1%;
    &:first-child {
      margin-bottom: ${mEls};
    }
  }
  @media screen and (min-width: ${breakpointMedium}) {
    flex-direction: row;
    justify-content: center;
    picture,
    img {
      &:first-child {
        margin-bottom: 0;
        margin-right: ${mEls};
      }
    }
  }
  .long {
    picture,
    img {
      width: 95%;
    }
  }
`;

export const ParallelImages2 = styled.div`
  display: flex;
  filter: drop-shadow(0 0 0.25rem rgba(87, 87, 87, 0.5));
  flex-direction: row;
  justify-content: space-around;
  img {
    border-radius: 1%;
    &:first-child {
      margin-bottom: ${mEls};
    }
  }
  @media screen and (min-width: ${breakpointMedium}) {
    flex-direction: row;
    img {
      &:first-child {
        margin-bottom: 0;
      }
      width: 65%;
    }
  }
`;
