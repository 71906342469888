import React from 'react';
import ResponsiveImage from './ResponsiveImage';
import ContactSection from './ContactSection';

import ENGProjectPreview3w1100x2webp from '../images/ENG/ENGProjectPreview3w1100@2x.webp';
import ENGProjectPreview3w1100x1webp from '../images/ENG/ENGProjectPreview3w1100@1x.webp';
import ENGProjectPreview3w1000x1jpg from '../images/ENG/ENGProjectPreview3w1100@1x.jpg';
import ENGProjectPreview3w1000x2jpg from '../images/ENG/ENGProjectPreview3w1100@2x.jpg';
import ENGLong1w10422xjpg from '../images/ENG/ENGLong1w1042@2x.jpg';
import ENGLong1w10422xwebp from '../images/ENG/ENGLong1w1042@2x.webp';
import ENGLong1w10421xwebp from '../images/ENG/ENGLong1w1042@1x.webp';
import ENGLong1w10421xjpg from '../images/ENG/ENGLong1w1042@1x.jpg';
import ENGLong2w4272xwebp from '../images/ENG/ENGLong2w427@2x.webp';
import ENGLong2w4272xjpg from '../images/ENG/ENGLong2w427@2x.jpg';
import ENGLong2w4271xwebp from '../images/ENG/ENGLong2w427@1x.webp';
import ENGLong2w4271xjpg from '../images/ENG/ENGLong2w427@1x.jpg';
import ENGLong3w4272xwebp from '../images/ENG/ENGLong3w427@2x.webp';
import ENGLong3w4272xjpg from '../images/ENG/ENGLong3w427@2x.jpg';
import ENGLong3w4271xwebp from '../images/ENG/ENGLong3w427@1x.webp';
import ENGLong3w4271xjpg from '../images/ENG/ENGLong3w427@1x.jpg';

import {
  StandardParagraph,
  PolygonBackground3,
  PortfolioPage,
  PortfolioSection,
  PortfolioLink,
  BrowserImage,
  LongImage,
  ParallelImages2,
} from './styled';

const Home = () => {
  return (
    <main>
      <PortfolioPage className="center-with-margin portfolio-animation">
        <h1>Erin Norman Gardens</h1>
        <h2>Overview</h2>
        <StandardParagraph>
          Erin wanted more business, what small business owner wouldn't?
          Starting from no online presence or branding, we had nowhere to go but
          up! Our strategy was to 1) get customers to her site with excellent
          SEO and 2) create high-yield leads. The project was a success: Erin's
          business has doubled and she now has three full time employees.
        </StandardParagraph>

        <PortfolioLink>
          <a
            href="https://erinnormangardens.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Visit Site
          </a>
          <a
            href="https://bitbucket.org/madronaweb/eng-foundation-frame/src/master/"
            target="_blank"
            rel="noopener noreferrer"
          >
            See Code
          </a>
        </PortfolioLink>
        <BrowserImage className="portfolio-animation">
          <ResponsiveImage
            bigWebp={ENGProjectPreview3w1100x2webp}
            smallWebp={ENGProjectPreview3w1100x1webp}
            bigJpeg={ENGProjectPreview3w1000x2jpg}
            smallJpeg={ENGProjectPreview3w1000x1jpg}
            altText="Project preview that shows Erin Norman Gardens homepage, about page, and maintenance page."
          />
        </BrowserImage>
      </PortfolioPage>
      <PolygonBackground3>
        <PortfolioPage className="center-with-margin grab-padding-for-polygon portfolio-animation">
          <PortfolioSection className="steal-margin-for-polygon">
            <h2>Design</h2>
            <StandardParagraph>
              Erin is a wonderfully talented gardener and designer. She creates
              beautiful gardens and has a legion of happy customers. The design
              needed to stay out of the way and showcase her skill and
              knowledge. The site grabs visitor's attention with beautiful
              images of clients' gardens, and connects with customers interested
              in sustainable gardening practices, creating leads interested in
              Erin's style of gardening. To lower the friction to contact, each
              page includes a contact button. I also designed an on-trend X logo
              from garden tools with initials and hand shovel.
            </StandardParagraph>
          </PortfolioSection>
          <PortfolioSection>
            <LongImage>
              <ResponsiveImage
                bigWebp={ENGLong1w10422xwebp}
                smallWebp={ENGLong1w10421xwebp}
                bigJpeg={ENGLong1w10422xjpg}
                smallJpeg={ENGLong1w10421xjpg}
                altText="Long screenshot of Erin Norman Gardens homepage."
              />
            </LongImage>
          </PortfolioSection>
          <PortfolioSection>
            <h2>Web Stack and Explanation</h2>
            <StandardParagraph>
              Foundation CSS was used for responsive media queries and grid
              layouts. Perch (PHP) was used to provide a CMS for each page and a
              blog. The contact form uses a custom Mailchimp popup overlay. A
              simple scroll to top button was created in JS.
            </StandardParagraph>
          </PortfolioSection>
        </PortfolioPage>
      </PolygonBackground3>

      <PortfolioPage className="center-with-margin steal-margin-for-polygon">
        <PortfolioSection className="steal-margin-for-polygon">
          <h2>Lessons Learned</h2>
          <StandardParagraph>
            This was the first modern website I authored. I'd made at least a
            dozen between 1996 and 2009, but just with HTML and minimal styles.
            The lessons I learned were enumerable, but here's what stands out.
          </StandardParagraph>
          <StandardParagraph>
            I learned responsive design using flexbox and media queries. (The
            site was refactored after learning Foundation CSS). I adopted the
            practice of mobile-first design: arranging content for a small
            viewport first, then modifying the styles as the viewport increases.
          </StandardParagraph>
          <StandardParagraph>
            Images were a priority and I learned to keep images sharp without
            sacrificing load times or the mobile experience. For graphics I
            exclusively used SVG. For photographs, my first solution was to load
            different sized images based on the viewport and that worked. Out of
            curiosity I worked out a hack to first lazy load a pixelated PNG
            with a CSS blur to smooth out the edges, then load the full and
            optimized JPG. Creating the pixelated PNGs in Photoshop and messing
            with two types of files and 6 lines of HTML for each image was time
            consuming. This is something I continue to experiment with and now
            use WEBP and media attributes to serve high-res images for Retina
            capable screens. I would love to learn how the big dogs like
            NYtimes.com and espn.com manage images with their content creators
            and CMS!
          </StandardParagraph>
        </PortfolioSection>
        <ParallelImages2>
          <LongImage>
            <ResponsiveImage
              bigWebp={ENGLong2w4272xwebp}
              smallWebp={ENGLong2w4271xwebp}
              bigJpeg={ENGLong2w4272xjpg}
              smallJpeg={ENGLong2w4271xjpg}
              altText="Long screenshot of Erin Norman Gardens design page."
            />
          </LongImage>
          <LongImage>
            <ResponsiveImage
              bigWebp={ENGLong3w4272xwebp}
              smallWebp={ENGLong3w4271xwebp}
              bigJpeg={ENGLong3w4272xjpg}
              smallJpeg={ENGLong3w4271xjpg}
              altText="Long screenshot of Erin Norman Gardens maintenance page."
            />
          </LongImage>
        </ParallelImages2>
      </PortfolioPage>
      <PortfolioSection>
        <ContactSection />
      </PortfolioSection>
    </main>
  );
};

export default Home;
