import React from 'react';

function ResponsiveAnimatedImage(props) {
  const { bigWebp, smallWebp, smallGif, altText } = props;
  return (
    <picture>
      <source srcSet={`${bigWebp} 2x, ${smallWebp} 1x`} type="image/webp" />
      <img src={smallGif} alt={altText} />
    </picture>
  );
}

export default ResponsiveAnimatedImage;
