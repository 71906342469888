import React from 'react';
import styled from 'styled-components';
import MBLogo from '../images/MBLogo.svg';
import LILogo from '../images/LILogo.svg';
import GHLogo from '../images/GHLogo.svg';
import { mSections, mEls } from './constants';

const MainFooter = styled.footer`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: ${mSections};
  margin-bottom: ${mEls};
  /* margin-bottom: 5rem; */
  img {
    height: 30px;
  }
`;

function Footer() {
  return (
    <MainFooter className="center-with-margin">
      <a
        href="https://github.com/macbubb"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={GHLogo} alt="github logo, octocat!" />
      </a>
      <a href="/">
        <img src={MBLogo} alt="Mac Bubb logo, an M and B" />
      </a>
      <a
        href="https://www.linkedin.com/in/mac-bubb/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={LILogo} alt="linkedin logo" />
      </a>
    </MainFooter>
  );
}

export default Footer;
